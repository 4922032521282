import React, { useState, useEffect } from "react";
import "../LandingPage/LandingPage.scss";
import { Link, NavLink } from "react-router-dom";
import Select from "react-select";
import { useHistory } from "react-router";

// IMAGE

import { GITHUB_ASSETS_HOST_URL } from "../../helpers/api";

const SiteHeader = () => {
  const [scroll, setScroll] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const showSidebar = () => setSidebar(!sidebar);
  const [sidebar, setSidebar] = useState(false);
  const [selectBoxValue, setSelectBoxValue] = useState([
    {
      label: "Parent",
      value: "Parent",
      Link: "/parent/login",
    },
    {
      label: "Learner",
      value: "Learner",
      Link: "/learner/login",
    },
    {
      label: "Educator",
      value: "Educator",
      Link: "/educator/login",
    },
  ]);
  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 100);
    });
    return () => {
      setScroll(false);
    };
  }, []);

  const history: any = useHistory();

  const customStyles = {
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      border: "none",
      height: 50,
      borderRadius: 20,
      width: 109,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "white" : "black",
      padding: 20,
      cursor: "pointer",
    }),
    control: (base) => ({
      ...base,
      width: "100%",
      border: 0,
      borderRadius: 15,
      height: 50,
      maxHeight: 50,
      paddingLeft: 20,
      boxShadow: "none",
      filter: "drop-shadow(8.181px 10.103px 19px rgba(0,0,0,0.03))",
      cursor: "pointer",
    }),
    indicatorsContainer: (state) => ({
      ...state,
      svg: {
        width: 0,
        height: 0,
        borderLeft: "8px solid transparent",
        borderRight: "8px solid transparent",
        borderTop: "9px solid #34c4e1",
        borderRadius: "3px",
      },
    }),
  };

  return (
    <>
      {width > 1025 ? (
        <div>
          <div
            className={
              scroll ? "landing-header landing_header_scroll" : "landing-header"
            }
          >
            <div className="header-alignment">
              <div></div>
              <NavLink to="/">
                <div className="logo">
                  {/* <img src={MeekoLogo} alt="MeekoLogo" /> */}
                  <img
                    src={
                      GITHUB_ASSETS_HOST_URL +
                      `MeekoAssets/footer-white-logo.png`
                    }
                    alt="MeekoLogo"
                  />
                </div>
              </NavLink>
              <div className="menu">
                <nav>
                  <ul>
                    <li>
                      <NavLink
                        exact
                        className="Edu_UnACtive_Side"
                        to="/"
                        activeClassName="active_nav_Side"
                      >
                        Home
                      </NavLink>
                    </li>

                    <li>
                      <NavLink
                        exact
                        className="Edu_UnACtive_Side"
                        to="/explore-class"
                        activeClassName="active_nav_Side"
                      >
                        Explore Class
                      </NavLink>
                    </li>

                    {/* <li>
                      <NavLink
                        exact
                        className="Edu_UnACtive_Side"
                        to="/pre-recorded-classes"
                        activeClassName="active_nav_Side"
                      >
                      Pre Recorded
                      </NavLink>
                    </li> */}

                    <li>
                      <NavLink
                        exact
                        className="Edu_UnACtive_Side"
                        to="/earn-on-meeko"
                        activeClassName="active_nav_Side"
                      >
                        Earn on Meeko
                      </NavLink>
                    </li>

                    <li>
                      <NavLink
                        exact
                        className="Edu_UnACtive_Side"
                        to="/learn-on-meeko"
                        activeClassName="active_nav_Side"
                      >
                        Learn on Meeko
                      </NavLink>
                    </li>

                    <li>
                      <NavLink
                        exact
                        className="Edu_UnACtive_Side"
                        to="/help-desk"
                        activeClassName="active_nav_Side"
                      >
                        Help Desk
                      </NavLink>
                    </li>

                    <li>
                      <NavLink
                        exact
                        className="Edu_UnACtive_Side"
                        to="/meeko-games"
                        activeClassName="active_nav_Side"
                      >
                        Meeko Games
                      </NavLink>
                    </li>

                    <li className="last_child">
                      <NavLink
                        exact
                        className="Edu_UnACtive_Side"
                        to="/educator/signup"
                        activeClassName="active_nav_Side"
                      >
                        Join for Free
                      </NavLink>
                    </li>
                  </ul>
                </nav>
              </div>

              <div className="login">
                {/* <button>Login</button> */}
                <Select
                  styles={customStyles}
                  isSearchable={false}
                  placeholder="Login | Sign Up"
                  options={selectBoxValue}
                  onChange={(e) => {
                    history.push(e.Link);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className={
            scroll
              ? "Responsive_front_Header_Container landing_header_scroll"
              : "Responsive_front_Header_Container"
          }
        >
          <div className="showMenu" onClick={showSidebar}>
            <img
              src={GITHUB_ASSETS_HOST_URL + `img/Responsive/menu.png`}
              alt="menu"
              className="_Responsive_Img"
            />
          </div>

          <nav
            className={
              sidebar ? "nav-menu-educator active" : "nav-menu-educator"
            }
          >
            <li className="navbar-toggle" onClick={showSidebar}>
              <i className="fa fa fa-times"></i>
            </li>
            <ul
              className="nav-menu-items-edu"
              onClick={showSidebar}
              id="Edu_nav-menu-items"
            >
              <li className="nav-text">
                <NavLink to="/" className="Sidebar_Data">
                  <div className="Slider_Icon_Header"></div>
                  <span className="Title_Sidebar">Home</span>
                </NavLink>
              </li>

              <li className="nav-text">
                <NavLink to="/explore-class" className="Sidebar_Data">
                  <div className="Slider_Icon_Header"></div>
                  <span className="Title_Sidebar">Explore Class</span>
                </NavLink>
              </li>

              {/* <li className="nav-text">
                <NavLink to="/pre-recorded-classes" className="Sidebar_Data">
                  <div className="Slider_Icon_Header"></div>
                  <span className="Title_Sidebar"> Pre Recorded</span>
                </NavLink>
              </li> */}

              <li className="nav-text">
                <NavLink to="/earn-on-meeko" className="Sidebar_Data">
                  <div className="Slider_Icon_Header"></div>
                  <span className="Title_Sidebar">Earn on Meeko</span>
                </NavLink>
              </li>
              <li className="nav-text">
                <NavLink to="/learn-on-meeko" className="Sidebar_Data">
                  <div className="Slider_Icon_Header"></div>
                  <span className="Title_Sidebar">Learn on Meeko</span>
                </NavLink>
              </li>
              <li className="nav-text">
                <NavLink to="/help-desk" className="Sidebar_Data">
                  <div className="Slider_Icon_Header"></div>
                  <span className="Title_Sidebar">Help Desk</span>
                </NavLink>
              </li>
              <li className="nav-text">
                <NavLink to="/meeko-games" className="Sidebar_Data">
                  <div className="Slider_Icon_Header"></div>
                  <span className="Title_Sidebar">Meeko Games</span>
                </NavLink>
              </li>
              

              <li className="nav-text">
                <NavLink to="/" className="Sidebar_Data">
                  <div className="Slider_Icon_Header"></div>
                  <span className="Title_Sidebar"></span>
                </NavLink>
              </li>
            </ul>
          </nav>
          <div className="Header_logo">
            <NavLink to="/dashboard">
              <img
                src={
                  GITHUB_ASSETS_HOST_URL + `MeekoAssets/footer-white-logo.png`
                }
                alt="logo"
                className="Logo_Responsive"
              />
            </NavLink>
          </div>

          <div className="login">
            <Select
              styles={customStyles}
              isSearchable={false}
              placeholder=""
              options={selectBoxValue}
              onChange={(e) => {
                history.push(e.Link);
              }}
            />
            {/* <img src={Login} alt="Login" className="Login1" /> */}
            <img
              src={GITHUB_ASSETS_HOST_URL + `img/Responsive/person1.png`}
              alt="Login"
              className="Login1"
            />
            {/* <img src={Login1} alt="Login1" className="Login2" /> */}
            <img
              src={GITHUB_ASSETS_HOST_URL + `img/Responsive/person.png`}
              alt="Login1"
              className="Login2"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default React.memo(SiteHeader);
