import { useState, useEffect, useRef } from "react";
import SiteHeader from "../SiteHeader/SiteHeader";
import SiteFooter from "../SiteFooter/SiteFooter";
import "./Front.scss";
import Slider, { CustomArrowProps } from "react-slick";
import api, {
  GITHUB_ASSETS_HOST_URL,
  ENDPOINT,
  decryptAPIRes,
  ASSETS_HOST_URL,
} from "../../helpers/api";
import GuestCommonCard from "../layout/GuestCommonCard/GuestCommonCard";
import { useHistory } from "react-router";
import axios from "axios";
import { Helmet } from "react-helmet";
import ClassCardPlaceHolder from "../SitePlaceHolder/ClassCardPlaceHolder";
import FrontCategoryPlaceHolder from "../SitePlaceHolder/FrontCategoryPlaceHolder";
import useWindowSize from "../dashboard/useWindowSize";
import { Link } from "react-router-dom";
import React from "react";
import ContentLoader from "react-content-loader";

const Front = ({ key, Value, deleteCard }: any) => {
  let source = axios.CancelToken.source();
  const mountedRef = useRef(true);
  const [loading, setLoading]: any = useState(true);
  const [freeClasses, setFreeClasses] = useState([]);
  const { width, height }: any = useWindowSize();
  const history: any = useHistory();
  const [popularClasses, setPopularClasses] = useState([]);
  const [toggleState, setToggleState] = useState(1);
  const [category, setCategory] = useState<any>([]);
  const FrontVideoRef: any = useRef(null);
  const toggleTab = (index) => {
    setToggleState(index);
  };

  const handleClassCategory = (id): any => {
    history.push(`/course/${id}`);
  };
  // CARD
  const [classesData, setClassesData]: any = useState();
  const [educatorData, setEducatorData]: any = useState();
  const [categoryData, setCategoryData]: any = useState();
  const role = localStorage.getItem("role");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    (async function () {
      window.scrollTo(0, 0);
      try {
        const response = await axios.get(ENDPOINT + "/front");
        const payload = decryptAPIRes(response.data.data);
        console.log(mountedRef.current, "mountedRef.current");
        if (mountedRef.current && payload.code === 200) {
          console.log(payload, "===payload");
          setCategory(payload?.data?.category);
          setFreeClasses(payload?.data?.freeClasses);
          setPopularClasses(payload?.data?.popularClasses);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (e) {
        setLoading(false);
        console.log("e", e);
      }
    })();

    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (Value?.classDetail) {
      const data = { ...Value?.classDetail };
      setClassesData(data);
    } else {
      setClassesData(Value);
      setCategoryData(Value?.category);
    }
    setCategoryData(Value?.category);
    setEducatorData(Value?.educator);

    return () => {
      setClassesData(undefined);
      setCategoryData(undefined);
      setEducatorData(undefined);
    };
  }, [Value]);

  const NextArrow = ({
    currentSlide,
    slideCount,
    ...props
  }: CustomArrowProps) => (
    <div {...props} className="slick-next slick-arrow">
      <i className="fa fa-angle-right fa-10x" color="Black"></i>
    </div>
  );

  const PrevArrow = ({
    currentSlide,
    slideCount,
    Left,
    ...props
  }: CustomArrowProps) => (
    <div {...props} className="slick-prev slick-arrow">
      <i className="fa fa-angle-left fa-10x"></i>
    </div>
  );

  const settings = {
    dots: false,
    infinite: true,
    lazyLoad: true,
    // autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow: 1,
    centerMode: false,
    slidesToScroll: 1,
    initialSlide: 0,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          dots: false,
          infinite: true,
          // autoplay: true,
          speed: 200,
          slidesToShow: 1,
          centerMode: false,
          slidesToScroll: 1,
          prevArrow: <PrevArrow />,
          nextArrow: <NextArrow />,
        },
      },
    ],
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Learn, Play, Explore With Free Online Live Courses | Online Learning
          Platforms
        </title>
        <meta
          name="description"
          content="Meeko is an online learning platform that connects Educators, students, & parents to enhance the learning experience with virtual & free online live courses."
        />
        <meta
          name="keywords"
          content="meeko,online learning platforms,live class,online classes app,live online class,live online classes,virtual online classes,top online learning platforms,e learning platforms,online courses,free online courses,online kids classes"
        />
        <link rel="canonical" href={window.location.href} />
        <meta
          name="p:domain_verify"
          content="a9f612cc753ef5869762d6b2290483c6"
        />
      </Helmet>

      <div className="frontpage_wrapper">
        <SiteHeader />
        <div className="frontpage_main_section">
          {/* START SLIDER SECTION */}
          <div className="frontpage_slider_section">
            <div className="frontpage_slider">
              {loading ? (
                <div className="frontpage_banner new_banner">
                  <ContentLoader speed={2} className="Card_Container">
                    <rect
                      x="0"
                      y="-1"
                      rx="0"
                      ry="0"
                      width="100%"
                      height="inherit"
                    />
                  </ContentLoader>
                </div>
              ) : (
                <Slider accessibility={true} {...settings}>
                  {/* <div className="frontpage_banner frontpage_mb_banner festival_banner">
                    <img
                      src={GITHUB_ASSETS_HOST_URL + `img/front/curtain1.png`}
                      alt="curtain"
                      className="decoration_div"
                    />
                    <p>
                      Handmade Rakhi Competition for kids
                      <span className="main_content">
                        <span className="differentColor"> Get a chance to </span>{" "}
                        <br />
                        <span>win a hamper</span>{" "}
                        <span className="differentColor">worth</span> <br />
                      </span>
                      <div className="main"> Rs. </div>
                      <span>
                        <i>5000/-</i>
                      </span>
                    </p>
                    <img
                      src={GITHUB_ASSETS_HOST_URL + `img/front/gifts.png`}
                      alt="Gift box"
                      className="main_img"
                    />

                    <img
                      src={GITHUB_ASSETS_HOST_URL + `img/front/curtain2.png`}
                      alt="curtain"
                      className="decoration-div"
                    />
                  </div> */}

                  {/* <div className="frontpage_banner frontpage_mb_banner festival_second_banner">
                    <p>
                      <span>
                        Free Live{" "}
                        <span className="differentColor">Rakhi Class</span>
                      </span>
                      <br />
                      with an expert art teacher. <br />{" "}
                      <span className="third_line">
                        Exclusively for kids aged 5-15
                      </span>{" "}
                      <br />
                      <a
                        href="https://www.instagram.com/meeko.world/"
                        target="_blank"
                      >
                        <button>Join Now</button>
                      </a>
                    </p>

                    <img
                      src={GITHUB_ASSETS_HOST_URL + `img/front/second-img.png`}
                      alt="banner-img"
                      className="main_img"
                    />
                  </div> */}

                  <div className="frontpage_banner new_banner">
                    <img
                      src={GITHUB_ASSETS_HOST_URL + `img/front/Metaverse1.png`}
                      alt="meeko"
                      className="new_banner_img"
                    />

                    {/* <img src={require("../../assets/img/front/Metaverse1.png").default} alt="" /> */}
                    <p className="meta-verse">
                    Experience the planet Meeko in Metaverse.
                    </p>
                    <a 
                      className="try-beta-version"
                      href="https://www.spatial.io/s/Meekos-Virtual-World-63ad425e5b82ea000136a6f9?share=4675820077721700366"
                      target="_blank"
                    >Try the beta version</a>
                  </div>

                  <div className="frontpage_banner frontpage_mb_banner">
                    <img
                      src={GITHUB_ASSETS_HOST_URL + `img/front/banner-1.png`}
                      alt="free online courses"
                      className="first_banner"
                    />

                    <p>
                      <span>Learn anything</span> you like from the teacher of{" "}
                      <span>your choice</span>
                    </p>
                  </div>

                  <div className="frontpage_banner third_mb_banner third_slider">
                    <p>
                      The <span>Freedom</span> to choose your{" "}
                      <span>Educator</span>
                    </p>
                  </div>

                  <div className="frontpage_banner frontpage_mb_banner second_slider">
                    <img
                      src={GITHUB_ASSETS_HOST_URL + `img/front/banner-2.png`}
                      alt="meeko"
                      className="second_banner_img"
                    />

                    <p>
                      Play while you <span>learn</span>, learn while you{" "}
                      <span>play</span>
                    </p>
                  </div>
                </Slider>
              )}

              <div className="about_content">
                <p>
                  A place where you can find skills of your choice, teachers of
                  your choice, at a time of your choice
                </p>
              </div>
            </div>
          </div>

          {/* END SLIDER SECTION */}

          {/* START FREE DEMO CLASS  */}
          <div className="free_class_title">
            <h2>
              <span>Free</span> Demo Class
            </h2>
          </div>
          <div className="free_demo_class">
            <div className="Card_Container">
              <div className="cards_row">
                {loading && (width < 1025 || width > 1440) ? (
                  <>
                    <ClassCardPlaceHolder />
                    <ClassCardPlaceHolder />
                    <ClassCardPlaceHolder />
                    <ClassCardPlaceHolder />
                    <ClassCardPlaceHolder />
                    <ClassCardPlaceHolder />
                    <ClassCardPlaceHolder />
                    <ClassCardPlaceHolder />
                    <ClassCardPlaceHolder />
                    <ClassCardPlaceHolder />
                  </>
                ) : loading ? (
                  <>
                    <ClassCardPlaceHolder />
                    <ClassCardPlaceHolder />
                    <ClassCardPlaceHolder />
                    <ClassCardPlaceHolder />
                    <ClassCardPlaceHolder />
                    <ClassCardPlaceHolder />
                    <ClassCardPlaceHolder />
                    <ClassCardPlaceHolder />
                  </>
                ) : null}
                {!loading && freeClasses?.length > 0
                  ? freeClasses?.map((item, index) => {
                      if ((width < 1025 || width > 1440) && index < 10) {
                        return (
                          <div
                            className="Common_Card_Main_Container"
                            key={`Try Demo Class_${index}`}
                          >
                            <GuestCommonCard
                              Responsive="Responsive_card"
                              Value={item}
                              keyIndex={index}
                            />
                          </div>
                        );
                      } else if (index < 8) {
                        return (
                          <div
                            className="Common_Card_Main_Container"
                            key={`Try Demo Class_${index}`}
                          >
                            <GuestCommonCard
                              Responsive="Responsive_card"
                              Value={item}
                              keyIndex={index}
                            />
                          </div>
                        );
                      }
                    })
                  : null}
              </div>

              {!loading && freeClasses?.length == 0 && (
                <div
                  className="Common_Card_Main_Container 1"
                  key={`Try Demo Class1_no_class`}
                >
                  <p className="no-class-found">
                    We are doing our homework to bring the best classes for you.
                  </p>
                </div>
              )}
            </div>
            <div className="Explore_More_Btn">
              <button
                onClick={() => history.push("/online-classes/free-classes")}
              >
                Explore More
              </button>
            </div>
          </div>
          {/* END FREE DEMO CLASS */}

          {/* START CATEGORY */}
          <div className="category_main_section">
            <div className="category_row">
              <div className="category_title">
                <h2> Categories</h2>
                <img
                  src={GITHUB_ASSETS_HOST_URL + `MeekoAssets/blue-wave.png`}
                  alt="live class"
                  className="Blue_Wawe"
                />
              </div>

              <div className="category_container_section">
                <div className="category_container">
                  {loading && (
                    <>
                      <FrontCategoryPlaceHolder />
                      <FrontCategoryPlaceHolder />
                      <FrontCategoryPlaceHolder />
                      <FrontCategoryPlaceHolder />
                      <FrontCategoryPlaceHolder />
                      <FrontCategoryPlaceHolder />
                      <FrontCategoryPlaceHolder />
                      <FrontCategoryPlaceHolder />
                      <FrontCategoryPlaceHolder />
                    </>
                  )}
                  {!loading &&
                    category.map((mainCat, index) => {
                      return (
                        <div
                          className="category_box"
                          style={{ backgroundColor: "#" + mainCat?.colorCode }}
                          key={`category_box_${index}`}
                          onClick={() => handleClassCategory(mainCat.slug)}
                        >
                          <div className="category_image">
                            <img
                              src={
                                mainCat.path
                                  ? ASSETS_HOST_URL + mainCat.path
                                  : ""
                              }
                              className="hexa_icon"
                            />
                          </div>
                          <div className="category_name">
                            <p>{mainCat.name}</p>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>

          {/* END CATEGORY */}

          {/* START POPULAR CLASS */}

          <div className="free_demo_class">
            <div className="popular_class_box_section">
              <div className="class_title">
                <h2> Popular Class</h2>
                <img
                  src={GITHUB_ASSETS_HOST_URL + `MeekoAssets/blue-wave.png`}
                  alt="online learning platforms"
                  className="Blue_Wawe"
                />
              </div>
              <div className="Card_Container popular">
                <div className="cards_row">
                  {loading && (width < 1025 || width > 1440) ? (
                    <>
                      <ClassCardPlaceHolder />
                      <ClassCardPlaceHolder />
                      <ClassCardPlaceHolder />
                      <ClassCardPlaceHolder />
                      <ClassCardPlaceHolder />
                      <ClassCardPlaceHolder />
                      <ClassCardPlaceHolder />
                      <ClassCardPlaceHolder />
                      <ClassCardPlaceHolder />
                      <ClassCardPlaceHolder />
                    </>
                  ) : loading ? (
                    <>
                      <ClassCardPlaceHolder />
                      <ClassCardPlaceHolder />
                      <ClassCardPlaceHolder />
                      <ClassCardPlaceHolder />
                      <ClassCardPlaceHolder />
                      <ClassCardPlaceHolder />
                      <ClassCardPlaceHolder />
                      <ClassCardPlaceHolder />
                    </>
                  ) : null}
                  {!loading && popularClasses?.length > 0 && (
                    <>
                      {popularClasses?.map((item, index) => {
                        if ((width < 1025 || width > 1440) && index < 10) {
                          return (
                            <div
                              className="Common_Card_Main_Container"
                              key={`popularClasses_${index}`}
                            >
                              <GuestCommonCard
                                Responsive="Responsive_card"
                                Value={item}
                                keyIndex={index}
                              />
                            </div>
                          );
                        } else if (index < 8) {
                          return (
                            <div
                              className="Common_Card_Main_Container"
                              key={`popularClasses_${index}`}
                            >
                              <GuestCommonCard
                                Responsive="Responsive_card"
                                Value={item}
                                keyIndex={index}
                              />
                            </div>
                          );
                        }
                      })}
                    </>
                  )}
                </div>

                {popularClasses?.length == 0 && (
                  <div
                    className="Common_Card_Main_Container 1"
                    key={`Try Demo Class1_no_class`}
                  >
                    <p className="no-class-found">
                      We are doing our homework to bring the best classes for
                      you.
                    </p>
                  </div>
                )}
              </div>
              <div className="Explore_More_Btn">
                <button
                  onClick={() =>
                    history.push("/online-classes/popular-classes")
                  }
                >
                  Explore More
                </button>
              </div>
            </div>
          </div>

          {/* END POPULAR CLASS */}

          {/* START BANNER  */}

          <div className="site_banner_section">
            <div className="site_banner_row">
              <Slider accessibility={true} {...settings}>
                <div className="site_banner">
                  <h1>
                    Learn while playing with
                    {""} <span>spaceships</span> and <span>avatars</span>
                  </h1>
                </div>
              </Slider>
            </div>
          </div>
          {/* END BANNER  */}

          {/* Ultra Pro convenient way to use our Platform */}
          <div className="Ultra_Pro_section">
            <div className="Ultra_Pro_title_row">
              <div className="Top_Cont">
                <p>Ultra Pro convenient way to use our Platform </p>
                <img
                  src={
                    require("../../assets/MeekoAssets/blue-wave.png").default
                  }
                  alt="Blue_Wawe"
                  className="Blue_Wawe"
                />
              </div>
            </div>

            <div className="Ultra_Pro_content_row">
              <div className="Ultra_Pro_col Ultra_Pro_col_01">
                <div className="Ultra_Pro_content_block">
                  <p className="Ultra_Pro_min_font">Why download our</p>
                  <p className="Ultra_Pro_big_title">Application?</p>
                  <ul className="Ultra_Pro_text">
                    <li className="Ultra_Pro_li">
                      Instantly enroll in classes
                    </li>
                    <li className="Ultra_Pro_li">
                      Push notifications for class reminders
                    </li>
                    <li className="Ultra_Pro_li">
                      Attend classes even while traveling
                    </li>
                    <li className="Ultra_Pro_li">
                      Check your child’s schedule on the go
                    </li>
                    <li className="Ultra_Pro_li">
                      Get class recommendations via alerts
                    </li>
                    <li className="Ultra_Pro_li">
                      Simply open the app to see your child's progress
                    </li>
                  </ul>
                </div>
                <div className="Ultra_Pro_btn_block">
                  <div className="btn_outter">
                    <a
                      className="btn_inner"
                      href="https://play.google.com/store/apps/details?id=com.meeko.elearner
                  "
                      target="_blank"
                    >
                      <img
                        src={
                          require("../../assets/img/Ultra_Pro/BGstore.png")
                            .default
                        }
                        className="ultra_BGstore"
                      />
                      <span className="btn_inner_text">Android</span>
                    </a>
                  </div>

                  {/* <div className="btn_outter">
                  <a className="btn_inner" href="#">
                    <img
                      src={
                        require("../../assets/img/Ultra_Pro/BGapple.png").default
                      }
                      className="ultra_BGapple"
                    />
                    <span className="btn_inner_text">iOS</span>
                  </a>
                </div> */}
                </div>
              </div>

              <div className="Ultra_Pro_col Ultra_Pro_col_02">
                <img
                  src={require("../../assets/img/Ultra_Pro/Bell.png").default}
                  className="ab_ultra_bell"
                />
                <img
                  src={require("../../assets/img/Ultra_Pro/play.png").default}
                  className="ab_ultra_play"
                />
                <img
                  src={
                    require("../../assets/img/Ultra_Pro/mobile_cell.png")
                      .default
                  }
                  className="ultra_mobile_cell"
                />
                <img
                  src={
                    require("../../assets/img/Ultra_Pro/onlinelaunching.png")
                      .default
                  }
                  className="ab_ultra_onlinelaunching"
                />
              </div>
            </div>
          </div>

          {/* END */}

          {/* START SIGNUP SECTION */}
          <div className="sign_up_section">
            <div className="sign_up_grid_row">
              <div className="sign_up_grid">
                <div className="sign_up_grid_item">
                  <img
                    src={GITHUB_ASSETS_HOST_URL + `img/front/parent.png`}
                    alt="live online class"
                  />

                  <p className="title">A Parents Getaway! </p>

                  <p>
                    Parents, your child will fall in love with studying. Meeko
                    has gamified learning which will keep your child ENGAGED and
                    ENTERTAINED.
                  </p>

                  <div className="sign_up_btn">
                    <button onClick={() => history.push("/parent/signup")}>
                      Setup your account
                    </button>
                  </div>
                </div>
                <div className="sign_up_grid_item">
                  <img
                    src={GITHUB_ASSETS_HOST_URL + `img/front/educator.png`}
                    alt="virtual online classes"
                  />

                  <p className="title">A Students Gaming & Learning Space!</p>

                  <p>
                    Kids, A place for you to LEARN new skills, DISCOVER
                    innovative activities, and EXPLORE fresh concepts while
                    being rewarded! Be fearless and let your imagination run
                    wild.
                  </p>

                  <div className="sign_up_btn">
                    <button onClick={() => history.push("/explore-class")}>
                      Explore Class
                    </button>
                  </div>
                </div>

                <div className="sign_up_grid_item">
                  <img
                    src={GITHUB_ASSETS_HOST_URL + `img/front/student.png`}
                    alt="top online learning platforms"
                  />

                  <p className="title"> An Educator’s Broadway Stage!</p>
                  <p>
                    Educators, earn money by doing what you love from ANYWHERE,
                    ANYTIME. Build your career as a modern educator at YOUR
                    TIME,YOUR PLACE, YOUR WAY.
                  </p>
                  <div className="sign_up_btn">
                    <button onClick={() => history.push("/educator/signup")}>
                      Sign up
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* END SIGNUP SECTION */}

          {/* START FIND OUT SECTION */}

          <div className="find_out_body">
            <div className="find_out_section">
              <div className="find_out_row">
                <div className="find_out_col">
                  <h2> Know more </h2>

                  <div className="find_out_left">
                    <button
                      type="submit"
                      className={
                        toggleState === 1
                          ? "find_out_button find_out_faq active-tabs"
                          : "tabs"
                      }
                      onClick={() => toggleTab(1)}
                    >
                      Upgrade your child’s skills with the latest knowledge
                    </button>
                    <button
                      type="submit"
                      className={
                        toggleState === 2
                          ? "find_out_button find_out_tuto active-tabs"
                          : "tabs"
                      }
                      onClick={() => toggleTab(2)}
                    >
                      Kids see the amazing journey that is waiting for you
                    </button>

                    <button
                      type="submit"
                      className={
                        toggleState === 3
                          ? "find_out_button find_out_tuto active-tabs"
                          : "tabs"
                      }
                      onClick={() => toggleTab(3)}
                    >
                      Teachers discover how easy teaching & earning can be
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {toggleState === 1 && (
              <div
                className={
                  toggleState === 1
                    ? "find_out_more_section active-content"
                    : "content"
                }
              >
                <div className="find_out_more_row">
                  <div className="find_out_more_col">
                    <video
                      className="Card_Img"
                      ref={FrontVideoRef}
                      preload="none"
                      poster={
                        GITHUB_ASSETS_HOST_URL + `img/first-thumbnail.jpg`
                      }
                      controls
                      loop
                      src="https://cdn.jsdelivr.net/gh/meeko007/meeko@main/assets/first-video.mp4"
                    >
                      <source
                        src="https://cdn.jsdelivr.net/gh/meeko007/meeko@main/assets/first-video.mp4"
                        type="video/M4V"
                      />
                      <source
                        src="https://cdn.jsdelivr.net/gh/meeko007/meeko@main/assets/first-video.mp4"
                        type="video/ogg"
                      />
                      <source
                        src="https://cdn.jsdelivr.net/gh/meeko007/meeko@main/assets/first-video.mp4"
                        type="video/webm"
                      />
                    </video>
                  </div>
                </div>
              </div>
            )}

            {toggleState === 2 && (
              <div
                className={
                  toggleState === 2
                    ? "find_out_more_section active-content"
                    : "content"
                }
              >
                <div className="find_out_more_row">
                  <div className="find_out_more_col">
                    <video
                      className="Card_Img"
                      ref={FrontVideoRef}
                      preload="none"
                      poster={
                        GITHUB_ASSETS_HOST_URL + `img/second-thumbnail.jpg`
                      }
                      controls
                      loop
                      src="https://cdn.jsdelivr.net/gh/meeko007/meeko@main/assets/second-video.mp4"
                    >
                      <source
                        src="https://cdn.jsdelivr.net/gh/meeko007/meeko@main/assets/second-video.mp4"
                        type="video/M4V"
                      />
                      <source
                        src="https://cdn.jsdelivr.net/gh/meeko007/meeko@main/assets/second-video.mp4"
                        type="video/ogg"
                      />
                      <source
                        src="https://cdn.jsdelivr.net/gh/meeko007/meeko@main/assets/second-video.mp4"
                        type="video/webm"
                      />
                    </video>
                  </div>
                </div>
              </div>
            )}

            {toggleState === 3 && (
              <div
                className={
                  toggleState === 3
                    ? "find_out_more_section active-content"
                    : "content"
                }
              >
                <div className="find_out_more_row">
                  <div className="find_out_more_col">
                    <video
                      className="Card_Img"
                      ref={FrontVideoRef}
                      preload="none"
                      poster={
                        GITHUB_ASSETS_HOST_URL + `img/third-thumbnail.jpg`
                      }
                      controls
                      loop
                      src="https://cdn.jsdelivr.net/gh/meeko007/meeko@main/assets/third-video.mp4"
                    >
                      <source
                        src="https://cdn.jsdelivr.net/gh/meeko007/meeko@main/assets/third-video.mp4"
                        type="video/M4V"
                      />
                      <source
                        src="https://cdn.jsdelivr.net/gh/meeko007/meeko@main/assets/third-video.mp4"
                        type="video/ogg"
                      />
                      <source
                        src="https://cdn.jsdelivr.net/gh/meeko007/meeko@main/assets/third-video.mp4"
                        type="video/webm"
                      />
                    </video>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="front-story-wrapper">
            <h2 className="front-story-heading">A story of stressed parents</h2>
            <p className="front-story-txt">
              There lived a Mojo family. As happy as their name sounds they were
              quite stressed, you may wonder why? The whole family was very
              talented and had incredible skills. They wanted the same for their
              kid ‘Baby Mojo’. Baby Mojo wanted to learn new things but he got
              tired of the educators available near him who had a very basic
              knowledge of those skills and had very limited options of
              educators. Mamma Mojo searched so many online learning platforms
              for live online classes so that Baby Mojo can learn new skills at
              home and enjoy the learning process. She tried so many e-learning
              platforms, virtual online classes, and even online classes app but
              nothing seems interesting for Baby Mojo. Baby mojo wanted
              something really interesting with various options available so he
              can explore and find out what he is interested in. Mama Mojo was
              very stressed about Baby Mojo. Seeing Mamma Mojo under stress Papa
              Mojo took matters into his hands and went on a hunt for top online
              learning platforms which provides virtual online classes for kids.
              In the search, he came across various platforms and looked at all
              the courses and classes provided by various online learning
              platforms but they all had some drawbacks.
            </p>
            <p className="front-story-txt">
              Papa Mojo was about to give up but then he found Meeko. He saw
              that not only does Meeko provides live online classes but also
              free online courses for kids with so many skills and educators to
              choose from. Papa Mojo showed this to Mamma Mojo and she got
              excited because she knew Baby Mojo will love this as Meeko also
              has mini-games for kids and they also give rewards once your child
              completes a number of classes Mamma Mojo booked a live class for
              Baby Mojo and he loved it. What Baby Mojo loved about Meeko is how
              the whole platform is made for kids and has a space theme. Baby
              Mojo got excited when he received spaceship parts when he
              completed his live class. The platform is so easy to use that Baby
              Mojo browses online courses by himself. He also loves his avatar
              and styles it according to his style. Baby Mojo finally got good
              teachers who are pros in their skills and taught him very well.
              Mamma Mojo now always books live online classes for Baby Mojo on
              Meeko. Mamma Mojo also gives Baby Mojo digital pocket money that
              is meekoins which he can use for buying cool accessories for his
              avatar. Now the Mojo parents are no more stressed about Baby Mojo.
            </p>

            <h2 className="front-story-heading">
              Online Learning Platforms - The Modern Approach To Learning
            </h2>
            <p className="front-story-txt">
              The first goal of the Internet was to facilitate better means of
              interaction. It has many applications in today's society. The
              Internet's growth has been incredibly beneficial to the academic
              world. Today, more and more people use the Internet as a means of
              learning, communicating, and showcasing their talents. The
              traditional classroom has taken a back seat to online education.
              There is now a wide variety of high-quality educational resources
              accessible to everyone with an internet connection that provides
              live online classes.
            </p>
            <h2 className="front-story-heading">
              Kids-friendly online learning
            </h2>
            <p className="front-story-txt">
              Meeko World is a unique{" "}
              <Link to="/explore-class">online learning platform for kids</Link>{" "}
              that offers various free online courses. Kids learn valuable
              skills with fun using our platform. Our venues for children's
              online education are now teaching them using multiple internet
              resources that are currently available. Many students have found
              that an alternative to the standard learning approach in a
              classroom setting is both practical and beneficial to them.
              Learning environments that are accessible online are coming up
              with a variety of creative techniques to instruct youngsters in a
              way that is both interesting and enjoyable for them. Not only are
              educational activities for children that take place online
              efficient, but they also contribute to the general growth and
              development of the children who participate.
            </p>
            <h2 className="front-story-heading">
              Create your online course with Meeko
            </h2>
            <p className="front-story-txt">
              With the expansion of online resources, students now have access
              to a vast pool of qualified educators worldwide. Anyone with the
              knowledge to share is welcome to join the Meeko world team. If you
              are a teacher with a specialized area of expertise, you may
              utilize our platform to publish courses and distribute your
              teachings to students. Our service allows users to participate in
              live online classes. Our state-of-the-art technology will enhance
              your lessons, so that teaching child is easier than ever.
            </p>
            <h2 className="front-story-heading">
              Meeko world- Online learning platform for Kids
            </h2>
            <p className="front-story-txt">
              Meeko is a kid-friendly online learning platform. We have reduced
              our courseware to guarantee that students learn through hands-on
              activities and interactive media. These education methods assist
              kids in cultivating the mental abilities that will serve them well
              in school and life. A child between the ages of 5 and 15 would
              benefit most from this curriculum. Our specialists have created a
              platform where kids may take live classes to master valuable
              skills without having to devote extra time to schoolwork.
            </p>
            <h2 className="front-story-heading">Why choose Meeko world</h2>
            <p className="front-story-txt">
              As a parent, if you are searching for options that provide quality
              education with fun for your kid. Your search ends here at Meeko
              world. Meeko offers a wide variety of live paid online lessons
              taught by qualified teachers and free online courses for children.
              Mini-games and awards for completing sets of classes keep kids
              engaged and enthusiastic about learning on Meeko.
            </p>
            <p className="front-story-txt">
              Meeko is an excellent platform for kids because it is tailored to
              their needs and decorated in a fun outer space theme. Your kid
              will be thrilled to get spacecraft components after the live
              class. Because of how intuitive the system is, your child can look
              through available online classes independently. Furthermore, he
              takes great pride in the appearance of his avatar, which he often
              customizes to reflect his tastes.
            </p>
            <p className="front-story-txt">
              At Meeko World, educators are experts in various fields like
              Academic, Art and craft, Coding, Content creation and editing,
              Health and Wellness, Life Skills, world lanunguage etc. Our expert
              teachers know how to convey information to children. Meekoins, a
              type of digital pocket money, can be given to a kid so that they
              can purchase fun items for their virtual character.
            </p>
            <p className="front-story-txt">
              Gift your kid a new learning experience with fun.{" "}
              <Link to="/contact-us">Contact us</Link> if you have any queries
              regarding Meeko world.
            </p>
          </div>
          {/* END FIND OUT SECTION */}
        </div>
        <SiteFooter />
      </div>
    </>
  );
};

export default React.memo(Front);
