import React from "react";
import ContentLoader from "react-content-loader";
import useWindowSize from "../dashboard/useWindowSize";

const ClassCardPlaceHolder = () => {
    const { width, height }: any = useWindowSize();
    return (
        <div 
            className="Common_Card_Main_Container"
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: width < 481 ? '249px' : (width < 1025 ? '258px' : (width < 1141 ? '319px' : (width < 1281 ? '359px' : '407px')))
            }}
        >
            <ContentLoader
                speed={2}
                className="Common_Card_Container"
                style={{
                    height: width < 481 ? '249px' : (width < 1025 ? '258px' : (width < 1141 ? '319px' : (width < 1281 ? '359px' : '407px')))
                }}
            >
                <rect 
                    x="0" 
                    y="-1" 
                    rx="0" 
                    ry="0" 
                    width="350" 
                    height="480" 
                />
            </ContentLoader>
        </div>
    );
}

export default React.memo(ClassCardPlaceHolder);