import React from "react";
import ContentLoader from "react-content-loader";

const FrontCategoryPlaceHolder = () => {
    return (
        <ContentLoader
            speed={2}
            className="category_box"
        >
            <rect 
                x="0" 
                y="-1" 
                rx="0" 
                ry="0" 
                width="330" 
                height="216" 
            />
        </ContentLoader>
    );
}

export default React.memo(FrontCategoryPlaceHolder);