import React, { useEffect, useRef, useState } from "react";
import "./GuestCommonCard.scss";
import api, {
  ASSETS_HOST_URL,
  ENDPOINT,
  decryptAPIRes,
  GITHUB_ASSETS_HOST_URL,
} from "../../../helpers/api";
import { getCookie, setCookie } from "../../../helpers/helpFunctions";
import { useHistory } from "react-router";
import axios, { AxiosRequestConfig } from "axios";
import Rating from "react-rating";
import $ from "jquery";
const GuestCommonCard = ({
  Value,
  keyIndex,
  Responsive,
  deleteCard,
  type,
}: any) => {
  const [classesData, setClassesData]: any = useState();
  const [educatorData, setEducatorData]: any = useState();
  const [categoryData, setCategoryData]: any = useState();
  const [isVideoLoaded, setIsVideoLoaded]: any = useState(false);
  const history = useHistory();
  const role = localStorage.getItem("role");
  const VideoRef: any = useRef(null);
  const [VideoHandler, setVideoHandler] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (Value?.classDetail) {
      const data = { ...Value?.classDetail };
      setClassesData(data);
    } else {
      setClassesData(Value);
      setCategoryData(Value?.category);
    }
    setCategoryData(Value?.category);
    setEducatorData(Value?.educator);
  }, [Value]);

  var videos = document.querySelectorAll("video");
  var videoPlay: NodeListOf<Element> = document.querySelectorAll(".fa-play");

  const VideoPlayHandler = async (e, classId) => {
    for (var i = 0; i < videos.length; i++) {
      videos[i].addEventListener(
        "play",
        function () {
          pauseAll(this);
        },
        true
      );
    }
    if (VideoHandler) {
      let isViewerCookie = getCookie(`${classId}_video`);
      VideoRef.current.play().catch((e) => {
        console.log(e, "-------error");
      });
      if (isViewerCookie === "") {
        setCookie(`${classId}_video`);
        const payload = await api.get(`front/videoviews/${btoa(classId)}`);
      }
    } else {
      VideoRef.current.pause();
    }
    setVideoHandler((VideoHandler) => !VideoHandler);
  };

  const pauseAll = (elem) => {
    for (var i = 0; i < videos.length; i++) {
      //Is this the one we want to play?
      if (videos[i] == elem) continue;
      //Have we already played it && is it already paused?

      if (videos[i].played.length > 0 && !videos[i].paused) {
        videos[i].pause();
        // videos[i].load()
        // Then pause it now
        if (videoPlay[i]) {
          videoPlay[i].classList.remove("fa-pause");
          videoPlay[i].classList.add("fa-play");
        }
      } else {
      }
    }
  };

  const handleLikeDisLike = async (id, isLikedData) => {
    if (deleteCard) {
      deleteCard(id, isLikedData);
    } else {
      setClassesData({ ...classesData, isLiked: !isLikedData });
      try {
        if (localStorage.getItem("role") == "Parent") {
          try {
            const config: AxiosRequestConfig = {
              method: "get",
              url:
                ENDPOINT +
                `/classes/${isLikedData ? "dislike" : "like"}/${btoa(id)}`,
              headers: {
                "x-authorization": `${localStorage.getItem(
                  "selectedChildToken"
                )}`,
              },
            };
            axios(config).then(function (response) {
              var payload = decryptAPIRes(response.data.data);
              if (payload.status === 200) {
              }
            });
          } catch (err) {
            console.log("err", err);
          }
        } else {
          const payload = await api.get(
            `/front/${isLikedData ? "dislike" : "like"}/${btoa(id)}`
          );
          if (payload.status === 200) {
          }
        }
      } catch (e) {
        console.log("e", e);
      }
    }
  };

  const redirectToClassDetail = (classData) => {
    console.log(classData, "===classData");
    if (classData?.slug) {
      const ele = document.getElementById("full-class-details");
      ele?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
      history.push(`/classes/${classData?.slug}`);
    }
  };
  return (
    <div
      className={`Common_Card_Container ${keyIndex}`}
      style={{ background: "#" + categoryData?.colorCode }}
    >
      <div
        className="Card_Image_Container"
        onClick={(e) => {
          if (classesData?.video?.path) {
            VideoPlayHandler(e, classesData?._id);
          } else {
            redirectToClassDetail(classesData);
          }
        }}
      >
        {classesData?.video?.path && (
          <video
            className={`Card_Img video-Card_Img video-Card-Img-${keyIndex}`}
            ref={VideoRef}
            preload="none"
            poster={
              classesData?.images?.path
                ? ASSETS_HOST_URL + classesData?.images?.path
                : ""
            }
            src={
              classesData?.video?.path
                ? ASSETS_HOST_URL + classesData?.video?.path
                : ""
            }
          >
            <source
              src={
                classesData?.video?.path
                  ? ASSETS_HOST_URL + classesData?.video?.path
                  : ""
              }
              type="video/mp4"
            />
            <source
              src={
                classesData?.video?.path
                  ? ASSETS_HOST_URL + classesData?.video?.path
                  : ""
              }
              type="video/ogg"
            />
            <source
              src={
                classesData?.video?.path
                  ? ASSETS_HOST_URL + classesData?.video?.path
                  : ""
              }
              type="video/webm"
            />
          </video>
        )}
        {(classesData?.video?.path == undefined ||
          classesData?.video?.path == null ||
          classesData?.video?.path == "") && (
          <img
            className="Card_Img"
            src={
              classesData?.images?.path
                ? ASSETS_HOST_URL + classesData?.images?.path
                : ""
            }
          />
        )}
        {classesData?.video?.path && (
          <div className="play_Btn">
            {VideoHandler ? (
              <i className="fa fa-play" aria-hidden="true"></i>
            ) : (
              <i className="fa fa-pause" aria-hidden="true"></i>
            )}
          </div>
        )}
        {classesData?.free && (
          <div className="mostReviewedWrapper">
            <div className="mostReviewed">
              <p>{classesData?.free ? "Free Class" : "Most Reviewed"}</p>
            </div>
          </div>
        )}
      </div>
      <div className="Card_Title_Container">
        <div
          className="C_Title"
          onClick={() => redirectToClassDetail(classesData)}
        >
          {classesData?.title}
        </div>
        <div
          className="heart_Container"
          onClick={() =>
            handleLikeDisLike(classesData._id, classesData.isLiked)
          }
        >
          {classesData?.isLiked ? (
            <img
              src={GITHUB_ASSETS_HOST_URL + `img/fullheart.png`}
              alt="Notification_image"
            />
          ) : (
            <img
              src={GITHUB_ASSETS_HOST_URL + "img/heart.png"}
              alt="Notification_image"
            />
          )}
        </div>
      </div>
      <div
        className="Card_Title_Container Card_Title_Container_responsive_card"
        // onClick={() => redirectToClassDetail(classesData)}
        key="responsive_card"
      >
        <div
          onClick={() =>
            handleLikeDisLike(classesData._id, classesData.isLiked)
          }
          className="responsive_like_wrapper"
        >
          {classesData?.isLiked ? (
            <img
              src={GITHUB_ASSETS_HOST_URL + `img/fullheart.png`}
              alt="Notification_image"
            />
          ) : (
            <img
              src={GITHUB_ASSETS_HOST_URL + "img/heart.png"}
              alt="Notification_image"
            />
          )}
        </div>
        <div
          onClick={() => redirectToClassDetail(classesData)}
          className="responsive_view_wrapper"
        >
          <img
            src={GITHUB_ASSETS_HOST_URL + `img/eye (1).png`}
            alt="Eye_image"
          />
          <p>
            {classesData?.totalViews
              ? (classesData?.totalViews).toLocaleString()
              : 0}
          </p>
        </div>
        <div
          onClick={() => redirectToClassDetail(classesData)}
          className="responsive_review_wrapper"
        >
          {classesData?.totalReview === 0 ? (
            <img
              src={GITHUB_ASSETS_HOST_URL + `img/white_Star.png`}
              alt="white_start"
              className="Start_Img"
            />
          ) : (
            <img
              src={GITHUB_ASSETS_HOST_URL + `img/yellow_Star.png`}
              alt="yellow_start"
              className="Start_Img"
            />
          )}
          <p>{classesData?.totalReview}</p>
        </div>
      </div>
      <div
        className="Card_Age_Container"
        onClick={() => redirectToClassDetail(classesData)}
      >
        <div
          className="Age_Left_Side"
          style={{ background: "#" + classesData?.colorCode }}
        >
          AGE {classesData?.ageGroup?.minimum}-{classesData?.ageGroup?.maximum}
        </div>
        <div className="Eye_Right_Side">
          <img
            src={GITHUB_ASSETS_HOST_URL + `img/eye (1).png`}
            alt="Eye_image"
          />
          <p>
            {classesData?.totalViews
              ? (classesData?.totalViews).toLocaleString()
              : 0}
          </p>
        </div>
      </div>
      <div
        className="Card_Bottom_Container"
        onClick={() => redirectToClassDetail(classesData)}
      >
        <div className="Price-Container">
          <div className="Price">
            <div className="Price-text">
              <span>
                {!classesData?.free ? `₹${classesData?.price}` : `Free`}
              </span>
            </div>
            {/* ? (classesData.price).toLocalString() : 0}</div> */}
            <div className="Sub-Price">
              {!classesData?.free && (
                <>
                  <span>Per</span> <span>Session</span>
                </>
              )}
            </div>
          </div>
          <div className="Ratting">
            <div>
              {classesData?.totalReview === 0 ? (
                <img
                  src={GITHUB_ASSETS_HOST_URL + `img/white_Star.png`}
                  alt="white_start"
                  className="Start_Img"
                />
              ) : (
                <img
                  src={GITHUB_ASSETS_HOST_URL + `img/yellow_Star.png`}
                  alt="yellow_start"
                  className="Start_Img"
                />
              )}
            </div>
            <div>({classesData?.totalReview})</div>
          </div>

          <Rating
            emptySymbol="fa fa-star fa-2x"
            fullSymbol="fa fa-star fa-2x fa_Full"
            initialRating={classesData?.rating}
            readonly={true}
            className="rating_class"
          />
        </div>
        <div
          className="Card_Bottom"
          style={{ cursor: "pointer" }}
          onClick={() => redirectToClassDetail(classesData)}
        >
          {educatorData?.profilePic ? (
            <img
              src={
                educatorData?.profilePic.path
                  ? ASSETS_HOST_URL + educatorData?.profilePic.path
                  : ""
              }
              alt="icon"
              width="50px"
            />
          ) : (
            // <img src={MeekoIcon} alt="icon" width="50px" />
            <img
              src={GITHUB_ASSETS_HOST_URL + `img/mekko-icon.png`}
              alt="icon"
              width="50px"
            />
          )}
          {educatorData?.badgeLevel ? (
            <img
              src={
                educatorData?.badgeLevel.bgImagePath
                  ? ASSETS_HOST_URL + educatorData?.badgeLevel.bgImagePath
                  : ""
              }
              alt="icon"
              width="50px"
              className="edu_badgeLevel"
            />
          ) : (
            <></>
          )}
          <p> {educatorData?.fullName}</p>
        </div>
      </div>
    </div>
  );
};

export default React.memo(GuestCommonCard);
